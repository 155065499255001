<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :searchControl="searchControl"
      :apiName="apiName"
      :rules="rules"
      :editTitle="'系统通知'"
      :isInline="false"
      :labelWidth="'120px'"
    >
      <div slot="toolSub"></div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    // let self = this;
    return {
      remote: api.common,
      dialogVisible: false,
      apiName: "/systemNotice",
      searchVal: "",
      jobList: [],
      form: {
        userIdList: [],
        id: "",
        apiName: "/systemNotice",
        agentCode: "",
      },
      userList: [],
      typeList: [
        {
          label: "启用",
          value: 0,
        },
        {
          label: "禁用",
          value: 1,
        },
      ],
      bntLoading: false,
      searchControl: {
        firstSoltSpan: [5, 5, 5, 5],
        secondSoltSpan: [3, 3, 3, 3],
        thirdSoltSpan: [14, 14, 14, 14],
      },
      searchQuery: {},
      rules: {
        //传递给APPlist的表单验证
        title: [
          {
            required: true,
            message: "请输入标题",
            trigger: ["blur", "change"],
          },
        ],
        content: [
          {
            required: true,
            message: "请输入内容",
            trigger: ["blur", "change"],
          },
        ],
      },
      props: [
        {
          label: "标题",
          prop: "title",
          align: "center",
          width: "200",
        },
        {
          label: "内容",
          prop: "content",
          align: "center",
        },

        {
          label: "操作",
          align: "center",
          type: "operationLink",
          formatter: function (row) {
            const buttonList = [
              {
                text: "编辑",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-edit",
                type: "primary",
                handlerType: "edit",
                handler: function () {},
              },
              {
                text: "删除",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-delete",
                type: "danger",
                handlerType: "delete",
                handler: function () {},
              },
            ];
            return buttonList;
          },
        },
      ],
      formProps: [
        {
          label: "标题",
          prop: "title",
          type: "input",
        },
        {
          label: "内容",
          prop: "content",
          type: "input",
        },
      ],
    };
  },
  created() {
    // this.getUserList();
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}

.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
